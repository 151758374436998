import React from "react"
import * as image from "./rc.images.js"
import Home from "@mui/icons-material/Home"
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits"
import ContactsIcon from "@mui/icons-material/Contacts"
import DashboardIcon from "@mui/icons-material/Dashboard"
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic"
// import NavMenuTab1 from "../layout/HeaderSection/NavBar/NavMenu/NavMenuTab1"
// import NavMenuTab2 from "../layout/HeaderSection/NavBar/NavMenu/NavMenuTab2"
// import NavMenuTab3 from "../layout/HeaderSection/NavBar/NavMenu/NavMenuTab3"
// import NavMenuTab4 from "../layout/HeaderSection/NavBar/NavMenu/NavMenuTab4"
// import NavMenuTab5 from "../layout/HeaderSection/NavBar/NavMenu/NavMenuTab5"
// import NavMenuTab6 from "../layout/HeaderSection/NavBar/NavMenu/NavMenuTab6"

export const NavigationDataSource = {
  navBgImage: image.navigationBgImage,
  navLogo: image.navigationLogoImage,
  navmenus: [
    {
      id: "1",
      title: "首页",
      href: "/",
      expanded: false,
      icon: () => <Home sx={{ color: "#fff" }} />,
      component: <></>,
    },
    {
      id: "2",
      title: "篆文建站",
      href: "/website",
      expanded: true,
      icon: () => <ProductionQuantityLimitsIcon sx={{ color: "#fff" }} />,
      component: <></>,
    },
    {
      id: "3",
      title: "移动开发",
      href: "/mobile",
      expanded: true,
      icon: () => <DashboardIcon sx={{ color: "#fff" }} />,
      component: <></>,
    },
    {
      id: "4",
      title: "建站模板",
      href: "/template",
      expanded: false,
      icon: () => <DashboardIcon sx={{ color: "#fff" }} />,
      component: <></>,
    },
    {
      id: "5",
      title: "成功案例",
      href: "/case",
      expanded: false,
      icon: () => <HeadsetMicIcon sx={{ color: "#fff" }} />,
      component: <></>,
    },
    {
      id: "6",
      title: "解决方案",
      href: "/solution",
      expanded: false,
      icon: () => <HeadsetMicIcon sx={{ color: "#fff" }} />,
      component: <></>,
    },
    {
      id: "7",
      title: "关于篆文",
      href: "/about",
      expanded: true,
      icon: () => <ContactsIcon sx={{ color: "#fff" }} />,
      component: <></>,
    },
  ],
}

export const BannerSectionDataSource = {
  bannerBgImage: image.bannerBgImage,
}

export const ConSection10DataSource = {
  productlist: [
    {
      productModel: "网站定制",
      productImage: image.con1PcImage,
      children: [
        "模板建站",
        "官网定制",
        "营销网站",
        "电商平台",
        "系统&接口对接",
      ],
    },
    {
      productModel: "移动端开发",
      productImage: image.con1PhoneImage,
      children: ["H5开发", "公众号开发", "小程序开发", "APP开发"],
    },
    {
      productModel: "基础业务",
      productImage: image.con1OtherImage,
      children: ["", "", ""],
    },
  ],
}

export const ConSection20DataSource = {
  linkIcon: image.con2linkSvg,
  searchIcon: image.con2searchSvg,
  wrapper: {},
  casetitle: "经典案例",
  caselist: [
    {
      casename: "Your Title",
      caseimage: image.con2CaseImage1,
      casedesc: "",
      caselink: "#",
    },
    {
      casename: "Your Title",
      caseimage: image.con2CaseImage2,
      casedesc: "",
      caselink: "#",
    },
    {
      casename: "Your Title",
      caseimage: image.con2CaseImage3,
      casedesc: "",
      caselink: "#",
    },
  ],
}

export const ConSection30DataSource = {
  settings: {
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    draggable: false,
    arrows: false,
    pauseOnHover: false,
    autoplay: true,
    slidesToShow: 5,
    speed: 3000,
    autoplaySpeed: 3000,
    rows: 3,
    slidesPerRow: 1,
    cssEase: "linear",
  },
  partners: [
    { id: "01", name: "阿里邮箱", logo: image.con3PartnerLG01 },
    { id: "02", name: "阿里巴巴", logo: image.con3PartnerLG02 },
    { id: "03", name: "猪八戒", logo: image.con3PartnerLG03 },
    { id: "04", name: "360搜索", logo: image.con3PartnerLG04 },
    { id: "05", name: "腾讯云", logo: image.con3PartnerLG05 },
    { id: "06", name: "百度智能云", logo: image.con3PartnerLG06 },
    { id: "07", name: "华为云", logo: image.con3PartnerLG07 },
    { id: "08", name: "腾讯网", logo: image.con3PartnerLG08 },
    { id: "09", name: "百度推广", logo: image.con3PartnerLG09 },
    { id: "10", name: "阿里云", logo: image.con3PartnerLG10 },
    { id: "11", name: "腾讯企业邮箱", logo: image.con3PartnerLG11 },
    { id: "12", name: "七牛云", logo: image.con3PartnerLG12 },
    { id: "13", name: "支付宝", logo: image.con3PartnerLG13 },
    { id: "14", name: "财付通", logo: image.con3PartnerLG14 },
    { id: "15", name: "钉钉", logo: image.con3PartnerLG15 },
    { id: "16", name: "京东智联云", logo: image.con3PartnerLG16 },
    { id: "17", name: "网易企业邮箱", logo: image.con3PartnerLG17 },
    { id: "18", name: "公众号", logo: image.con3PartnerLG18 },
  ],
}

export const WebSiteDataSource = {
  navparam: {
    menuTabs: [
      { id: 0, label: "企业建站", href: "", component: "" },
      { id: 1, label: "营销网站", href: "" },
      { id: 2, label: "电商网站", href: "" },
      { id: 3, label: "高端定制", href: "" },
    ],
    service: [
      {
        subject: "推荐购买",
        children: [
          { id: 0, label: "企业域名" },
          { id: 1, label: "网站备案" },
          { id: 2, label: "云空间" },
          { id: 3, label: "网站安全" },
        ],
      },
      {
        subject: "网站推广",
        children: [
          { id: 0, label: "SEO优化" },
          { id: 1, label: "搜索排名" },
        ],
      },
    ],
  },
}

export const MobileDataSource = {
  navparam1: {
    menuTabs: [
      { id: 0, label: "微站", href: "", component: "" },
      { id: 1, label: "微商城", href: "" },
      { id: 2, label: "微应用", href: "" },
    ],
  },
  navparam2: {
    menuTabs: [
      { id: 0, label: "企业微信", href: "", component: "" },
      { id: 1, label: "钉钉应用", href: "" },
    ],
  },
  navparam3: {
    menuTabs: [
      { id: 0, label: "H5开发", href: "", component: "" },
      { id: 1, label: "APP开发", href: "" },
    ],
  },
}

export const AboutUsDataSourceDataSource = {
  headerBgImage: image.headerBgImage,
  headerImage: image.headerImage04,
  primarySubject: "我们的宗旨",
  secondarySubject:
    "求实创新、与时俱进为企业提供专业的一站式建站服务，助力中小企业数字化经营升级！",
  contentCompanyCulture: [
    {
      contentBackColor: "#ffffff",
      contentImage: image.aboutUsCompanyImage01,
      contentImageOrder: 1,
      contentPrimaryText: "关于篆文",
      contentSecondaryText: "",
      contentText:
        "武汉篆文信息科技有限公司(简称篆文)，是一家专注于提供网络营销，软件开发等技术服务的公司，助力中小企业信息化建设，提供一站式建站解决方案，为中小企业数字化管理升级，提供一体化软件解决方案。  \
        篆文以“求实创新，与时俱进”的理念，提供优质的互联网产品和服务。公司拥有几十年专业从事相关技术经验的开发人员，拥有自主研发的产品和完善的售后服务体系，提供以人为本的服务，篆文秉持客户至上的原则，竭诚为您提供最专业，最优质的服务。",
      contentTextOrder: 2,
    },
    {
      contentBackColor: "#f5f5f5",
      contentImage: image.aboutUsCompanyImage02,
      contentImageOrder: 2,
      contentPrimaryText: "篆文精神",
      contentSecondaryText: "创业创新，与时俱进",
      contentText:
        "武汉篆文信息科技有限公司（简称篆文）是一家助力中小企业数字化经营升级的企业，公司以“求实创新、与时俱进”的企业理念，为中小企业用户提供优质的互联网产品和服务。",
      contentTextOrder: 1,
    },
    {
      contentBackColor: "#ffffff",
      contentImage: image.aboutUsCompanyImage03,
      contentImageOrder: 1,
      contentPrimaryText: "篆文文化",
      contentSecondaryText: "",
      contentText:
        "武汉篆文信息科技有限公司（简称篆文）是一家助力中小企业数字化经营升级的企业，公司以“求实创新、与时俱进”的企业理念，为中小企业用户提供优质的互联网产品和服务。",
      contentTextOrder: 2,
    },
  ],
}

export const CaseDataSourceDataSource = {
  headerBgImage: image.headerBgImage,
  headerImage: image.headerImage02,
  caselist: [
    {
      casename: "Your Title",
      caseimage: image.con2CaseImage1,
      casedesc: "",
      caselink: "#",
    },
    {
      casename: "Your Title",
      caseimage: image.con2CaseImage2,
      casedesc: "",
      caselink: "#",
    },
    {
      casename: "Your Title",
      caseimage: image.con2CaseImage3,
      casedesc: "",
      caselink: "#",
    },
    {
      casename: "Your Title",
      caseimage: image.con2CaseImage4,
      casedesc: "",
      caselink: "#",
    },
    {
      casename: "Your Title",
      caseimage: image.con2CaseImage5,
      casedesc: "",
      caselink: "#",
    },
    {
      casename: "Your Title",
      caseimage: image.con2CaseImage6,
      casedesc: "",
      caselink: "#",
    },
  ],
}

export const FooterDataSourceDataSource = {
  footList: [
    {
      title: "网站建设",
      children: [
        { name: "营销网站制作" },
        { name: "高端网站设计" },
        { name: "模板网站建设" },
        { name: "商城网站开发" },
      ],
    },
    {
      title: "移动端建设",
      children: [
        { name: "手机网站建设" },
        { name: "小程序开发" },
        { name: "公众号开发" },
        { name: "APP开发" },
      ],
    },
    {
      title: "其他服务",
      children: [
        { name: "SEO网站优化" },
        { name: "域名注册" },
        { name: "云主机租用" },
        { name: "代理记账" },
      ],
    },
    {
      title: "联系篆文",
      children: [
        {
          name: "电话：19901672179",
        },
        {
          name: "邮箱：service@zw027.com",
        },
      ],
    },
  ],
}
