import React from "react"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack"
import ImageRotateX1 from "../components/Animation/ImageRotateX1"
import Header from "../components/Header"
import { CaseDataSourceDataSource } from "../data/data.source.js"

const ShowCase = props => {
  const { headerBgImage, headerImage, ...dataSource } = CaseDataSourceDataSource

  return (
    <div>
      <Header
        bgImage={headerBgImage}
        subjectImage={headerImage}
        title="案例展示"
        subtitle="您的满意是我们最大的动力！"
      />
      <CaseSection datasource={dataSource} {...props} />
    </div>
  )
}

function CaseSection(props) {
  const { datasource } = props
  const { caselist } = datasource
  return (
    <React.Fragment>
      <div>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            成功案例
          </Typography>
        </Container>
      </div>
      <Container >
        {/* End hero unit */}
        <Grid container spacing={4}>
          {caselist.map((card, i) => (
            <Grid item key={card} xs={12} sm={6} md={4}>
              <ImageRotateX1
                image={card.caseimage}
                title={card.casename}
                desc={card.casedesc}
                link={card.caselink}
              />
            </Grid>
          ))}
          <Grid item key="Pagination" xs={12} sm={12} md={12}>
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Pagination
                count={2}
                defaultPage={6}
                siblingCount={0}
                boundaryCount={2}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default ShowCase
